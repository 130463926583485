
:root {
  --color-gray: #343A40;
  --color-main: #EEB735;
}

body {
  background-color: black;
  color: var(--color-main);
  padding: 4rem 0 0 0;
}

a {
  color: var(--color-main);
  padding: 0.1rem;
  margin: 0.2rem;
}
a::after {
  content: "→";
}

button {
  background-color: black;
  border: thick outset;
  border-color:  var(--color-main);
  border-radius: 1rem;
  color: var(--color-main);
  padding: 0.5rem;
  margin: 0.2rem;
}
button:active {
  background-color: var(--color-main);
  color: black;
}
button:active:hover {
  background-color: var(--color-main);
  color: black;
}

div {
  margin: 0;
  padding: 0;
}

h1.center {
  text-align: center;
}
h2.center {
  text-align: center;
}

input {
  background-color: black;
  border: thin solid;
  border-color: var(--color-main);
  border-radius: 1rem 0 1rem 0;
  color: var(--color-main);
  padding: 0.5rem;
  font-size: 1.1rem;
}

label {
  margin-right: 1rem;
}

li {
  font-style: oblique;
  margin: 0 0 0.25rem 0;
  list-style-type: none;
}
ul > li {
  padding: 0 0 0 0.25rem;
}

select {
  background-color: black;
  border: thin solid;
  border-color:  var(--color-main);
  border-radius: 0 1rem 0 1rem;
  color: var(--color-main);
  padding: 0.5rem;
}

ul {
  margin: 0;
  padding: 0;
}

::placeholder {
  font-style: italic;
}

.button {
  touch-action: auto;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.capitalize {
  text-transform: capitalize;
}

.camera.button.direction {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
}

.center.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.column.content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-flow: column;
}
.scrunch.content {
  display: inline-flex;
  justify-content: space-around;
}

.control.button {
  background-color: black;
  padding: 0.6rem 1rem;
  color: var(--color-main);
  margin: 0.2rem;
  font-size: 1.25rem;
}
.control.value {
  background-color: black;
  border-bottom: thick outset gray;
  border-left: thick outset gray;
  border-radius: 1rem;
  padding: 0.8rem 1.6rem;
  color: var(--color-main);
  margin: 0.2rem;
  font-size: 1.25rem;
}

.control-list {
  padding: 0.3rem;
}

.delete.button {
  color: red;
  border: thin solid red;
  font-style: italic;
}

.flex.vertical {
  display: flex;
  flex-direction: column;
  /* 
  align-items: center;
  */
  justify-content: space-evenly; 
}

.flexrow.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

}

.refresh.button {
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  border-radius: 1rem;
  background-color: var(--color-main);
  padding: 0.1rem;
  margin: 0.1rem;
  z-index: 3;
}

.glow {
  -webkit-box-shadow: 0px 0px 2px 1px var(--color-main);
  box-shadow: 0px 0px 2px 1px var(--color-main);
}

.hidden {
  display: none;
}

.highlight {
  background-color: var(--color-main);
  color: black;
}

a.highlight.nav-link.nav-item {
  background-color: var(--color-gray);
  color: var(--color-main);
}

.load.gauge {
  position: relative;
  text-align: center;
}

.machine.interface {
  position: relative;
  /* background-color: #110; */
  border-radius: 1rem;

  margin: 0.1rem;
  padding: 0.1rem;
}

.add.machine.container .header {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.machine.interface .close.button {
  background-color: black;
  color: red;
}

.machine.interface .main.content {
  position: relative;
  display: inline-block;
  top: 1rem;
  left: 1rem;
}

.member.detail.label {
  font-style: normal;
}
.member.detail.label::after {
  content: ':';
}

.outline {
  border: thin outset var(--color-main);
  border-radius: 1rem;
  margin: 0.1rem;
}

.panel-header {
  display: inline-block;
  position: relative;
  background-color: black;
  border-bottom: thick outset gray;
  border-left: thick outset gray;
  border-right: thick outset gray;
  border-radius: 1rem;
  padding: 0.5rem 4rem;
  color: var(--color-main);
  margin: 0.2rem;
  font-size: 1.5rem;
  left: 50%;
  transform: translate(-50%);
}

.position-list {
  display: flex;
}
.position-list li {
  border-bottom: thin solid white;
  margin: 0.1rem 1rem;
}

.progress {
  /* display: inline-block; */
  background-color: var(--color-gray);
  border-radius: 1rem;
  height: 3rem;
  margin: 0.5rem;
  padding: 0.2rem;
  width: 75%;
}
.progress-bar {
  border-radius: 1rem;
  color: black;
  font-weight: 900;
  font-size: 2rem;
}

.row.panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.save.button {
  font-size: 2rem;
  border: thick outset var(--color-main);
  border-radius: 2rem;
  padding: 0.5rem 2rem;
}

.secure.link {
  color: red;
  padding: 0.66rem;
  margin: 0.5rem;
  border: 0.1rem outset red;
  height: 3rem;
  font-size: 1rem;
}

.session-header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.session-header span {
  font-size: 2rem;
  border-radius: 1rem;
}
.session-header span:first-child {
  border-bottom: thick outset gray;
  border-left: thick outset gray;
}
.session-header span:last-child {
  border-bottom: thick outset gray;
  border-right: thick outset gray;
}

.subtext {
  position: absolute;
  font-size: 1rem;
  left: 50%;
  bottom: 3rem;
  opacity: 0.8;
  transform: translate(-50%);
}

.tare.button {
  position: absolute;
  right: 5%;
  top: 0;
}